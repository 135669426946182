import * as z from 'zod';

import { UserId } from '../BrandedIds';
import { zodBrandedUuid } from '../utils';
import { DashboardTaskType } from './Task';
import { TaskStatus } from './TaskStatus';

export const TaskStatusFilter = z.object({
  type: z.literal('enum'),
  key: z.literal('status'),
  filter: z.object({
    values: z.array(TaskStatus),
  }),
});
export type TaskStatusFilter = z.infer<typeof TaskStatusFilter>;

export const TaskDueStatus = z.enum(['late', 'onTime', 'none']);
export type TaskDueStatus = z.infer<typeof TaskDueStatus>;
export const TaskDueStatusFilter = z.object({
  type: z.literal('enum'),
  key: z.literal('dueStatus'),
  filter: z.object({
    values: z.array(TaskDueStatus),
  }),
});
export type TaskDueStatusFilter = z.infer<typeof TaskDueStatusFilter>;

export const TaskAssignee = z.union([z.literal('none'), zodBrandedUuid<UserId>()]);
export type TaskAssignee = z.infer<typeof TaskAssignee>;
export const TaskAssigneeFilter = z.object({
  type: z.literal('enum'),
  key: z.literal('assignee'),
  filter: z.object({
    values: z.array(TaskAssignee),
  }),
});
export type TaskAssigneeFilter = z.infer<typeof TaskAssigneeFilter>;

export const TaskTypeFilter = z.object({
  type: z.literal('enum'),
  key: z.literal('type'),
  filter: z.object({
    values: z.array(DashboardTaskType),
  }),
});
export type TaskTypeFilter = z.infer<typeof TaskTypeFilter>;

export const TaskFilter = z.discriminatedUnion('key', [
  TaskStatusFilter,
  TaskTypeFilter,
  TaskDueStatusFilter,
  TaskAssigneeFilter,
]);
export type TaskFilter = z.infer<typeof TaskFilter>;
