import { WarningOutlined } from '@ant-design/icons';
import { useState } from 'react';

import { DATE_FORMAT_LONG, dateFormat, NamedMemo, paymentAmountFormat } from '@willow/shared-web';

import { WillowModal } from '../../modal/Modal';

import './ConfirmPaymentModal.scss';

interface Props {
  showModal: boolean;
  onClose: () => void;
  paymentAmount: number;
  bankName: string;
  autopayEnabled: boolean;
  paymentSubmit: () => Promise<void>;
}

export const ConfirmPaymentModal = NamedMemo<Props>(
  'ConfirmPaymentModal',
  ({ showModal, onClose, paymentAmount, bankName, autopayEnabled, paymentSubmit }) => {
    const [isSubmitting, setIsSubmitting] = useState(false);
    return (
      <WillowModal showModal={showModal} onClose={onClose}>
        <div className="modal-confirm-payment">
          <h2>{autopayEnabled && <WarningOutlined className="u-color-red1 me-2" />} Submit Payment Immediately?</h2>

          <div className="modal-confirm-payment__copy">
            {autopayEnabled ? (
              <p>
                You currently have auto-payment enabled, submitting payment today will override auto-payment and begin{' '}
                <span className="u-color-red1 fw-bold">processing for immediate withdraw today</span>.
              </p>
            ) : (
              <p>Confirming this payment will begin processing for immediate withdraw today.</p>
            )}
          </div>

          <ul className="modal-confirm-payment__details">
            <li>
              <div className="modal-confirm-payment__details__title">Amount</div>
              <div className="modal-confirm-payment__details__desc">{paymentAmountFormat(paymentAmount)}</div>
            </li>
            <li>
              <div className="modal-confirm-payment__details__title">From account</div>
              <div className="modal-confirm-payment__details__desc">{bankName}</div>
            </li>
            <li>
              <div className="modal-confirm-payment__details__title">Processing date</div>
              <div className="modal-confirm-payment__details__desc">{dateFormat(new Date(), DATE_FORMAT_LONG)}</div>
            </li>
          </ul>

          <div className="modal-confirm-payment__buttons">
            <button type="button" onClick={onClose} className="modal-confirm-payment__cancel-btn">
              {autopayEnabled ? 'No, do not submit payment' : 'Cancel'}
            </button>

            <button
              disabled={isSubmitting}
              onClick={async () => {
                try {
                  setIsSubmitting(true);
                  await paymentSubmit();
                } finally {
                  setIsSubmitting(false);
                }
              }}
              className="modal-confirm-payment__submit-btn"
              data-ghost="portal-loan--modal-submit-payment-button"
            >
              {autopayEnabled ? 'Yes, submit now' : 'Confirm Payment'}
            </button>
          </div>
        </div>
      </WillowModal>
    );
  },
);
