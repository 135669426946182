import * as z from 'zod';

import { EscrowPaymentHistoryItemId, PaymentConfigId } from '../BrandedIds';
import { zodBrandedUuid, zodDateOrString } from '../utils';

export const PaymentConfigSource = z.enum([
  'initial',
  'escrowMonthlyInsuranceManualUpdate',
  'updateBuydownInterestRate',
  'armUpdate',
  'armManualUpdate',
  'constructionToPermanentEvent',
  'updateDelinquentInterestRate',
  'manualUpdate',
]);

export type PaymentConfigSource = z.infer<typeof PaymentConfigSource>;

export const PaymentConfig = z.object({
  id: zodBrandedUuid<PaymentConfigId>(),
  source: PaymentConfigSource,
  referenceId: zodBrandedUuid<EscrowPaymentHistoryItemId>().optional(),
  createdAt: zodDateOrString,
  effectiveDate: zodDateOrString,
  type: z.enum(['fixed', 'arm']),
  isInterestOnly: z.boolean(),
  monthlyPrincipalAndInterestTotal: z.number(),
  monthlyInterestRate: z.number(),
  delinquentInterestRate: z.number().optional(),
  delinquentInterestDeferred: z.boolean().optional(),
  delinquentInterestWaived: z.boolean().optional(),
});
export type PaymentConfig = z.infer<typeof PaymentConfig>;
