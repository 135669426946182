import { FieldValues } from 'react-hook-form';

import { STATE_ABBREVIATIONS } from '@willow/types-iso';

import { ControlledSimpleSelect, ControlledSimpleSelectProps } from './ControlledSimpleSelect';

const OPTIONS = [
  ...STATE_ABBREVIATIONS.map((stateAbbreviation) => ({
    value: stateAbbreviation,
    label: stateAbbreviation,
  })),
];

type Props<T extends FieldValues> = Omit<ControlledSimpleSelectProps<T>, 'options'>;

export function ControlledStateSelect<T extends FieldValues>({
  control,
  fieldName,
  required,
  placeholder,
  ...props
}: Props<T>) {
  return (
    <ControlledSimpleSelect
      {...props}
      options={[
        ...OPTIONS,
        {
          value: '',
          label: placeholder || 'Select State',
        },
      ]}
      control={control}
      fieldName={fieldName}
      required={required}
    />
  );
}
