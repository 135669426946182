/*
    This view is displayed in leiu of the payment form for transferred loans
*/

import { CompanyFragment, PortalTransferDataFragment } from '@willow/graphql-iso/src/portal';
import { formatDateFromDate, phoneNumberFormat } from '@willow/shared-iso';
import { NamedMemo } from '@willow/shared-web';
import { parseGqlIsoDate } from '@willow/types-iso';

import { PillLabel } from '../../pill-label/PillLabel';
import { getPageCopy } from './util';

import './TransferredLoan.scss';

interface Props {
  company: CompanyFragment;
  transferData: NonNullable<PortalTransferDataFragment>;
  isPending: boolean; // copy adjustments based on status
}

export const TransferredLoan = NamedMemo<Props>('TransferredLoan', ({ company, transferData, isPending }) => {
  const { name: companyName } = company;
  const {
    servicerName,
    servicerLoanNumber,
    phone,
    correspondenceAddress,
    paymentAddress,
    servicerWebsite,
    servicerHours1,
    servicerHours2,
    servicerHours3,
  } = transferData;

  const transferEffectiveDate = parseGqlIsoDate(transferData.legalTransferEffectiveDate);
  const formattedDate = formatDateFromDate(transferEffectiveDate, 'MM/dd/yyyy');
  const formattedPhone = phoneNumberFormat(phone);
  const { line1, locality, region, postcode } = correspondenceAddress || paymentAddress;

  const pageCopy = getPageCopy(isPending);

  return (
    <>
      <section>
        <h1 className="transferred-loan__status">
          <PillLabel label="Loan Update" />
        </h1>

        <div className="transferred-loan__header">
          {pageCopy.heading}
          <span className="transferred-loan__header__name">{servicerName}</span>
        </div>
      </section>

      <section className="transferred-loan__container">
        <h2 className="transferred-loan__title">
          {pageCopy.title} to {servicerName} effective {formattedDate}
        </h2>

        <ul className="transferred-loan__list">
          <li>
            <h3 className="transferred-loan__list__title">What does this mean?</h3>
            <div className="transferred-loan__list__desc">
              {companyName} works with {servicerName} {pageCopy.whatMeansDesc}.
            </div>
          </li>

          <li>
            <h3 className="transferred-loan__list__title">Who is collecting my payments?</h3>
            <div className="transferred-loan__list__desc">
              On {formattedDate} {servicerName} will begin to collect your mortgage payments. Note: if you have auto-pay
              enabled with {companyName}, it will automatically be disabled.
            </div>
          </li>

          <li>
            <h3 className="transferred-loan__list__title">What is my new loan number?</h3>
            <div className="transferred-loan__list__desc">
              As part of the loan sale, your loan number will change. Your new loan number will be {servicerLoanNumber}.
              This number will be utilized in all correspondence with {servicerName}. Please note, this does not impact
              the terms or payment schedule for your mortgage in any way.
            </div>
          </li>

          <li>
            <h3 className="transferred-loan__list__title">How do I make payments?</h3>
            <div className="transferred-loan__list__desc">
              By {formattedDate} you will receive a letter from {servicerName} instructing you on how to make mortgage
              payments moving forward. {servicerName} is there to support you and answer any questions you may have
              about your loan.
            </div>
          </li>

          <li>
            <h3 className="transferred-loan__list__title">How do I contact {servicerName}?</h3>
            <div className="transferred-loan__list__desc">
              You may contact {servicerName} using the information provided below.
              <br />
              {line1}, {locality} {region} {postcode}
              <br />
              <a href={`tel:${phone}`}>{formattedPhone}</a>
              {servicerWebsite && (
                <>
                  <br />
                  <a href={servicerWebsite}>{servicerWebsite}</a>
                </>
              )}
              {servicerHours1 && (
                <>
                  <br />
                  {servicerHours1}
                </>
              )}
              {servicerHours2 && (
                <>
                  <br />
                  {servicerHours2}
                </>
              )}
              {servicerHours3 && (
                <>
                  <br />
                  {servicerHours3}
                </>
              )}
            </div>
          </li>

          <li>
            <h3 className="transferred-loan__list__title">Can I still use this portal?</h3>
            <div className="transferred-loan__list__desc">
              You will still be able to access past payment information here but you will no longer receive new
              statements or make payments through this platform.
            </div>
          </li>
        </ul>
      </section>
    </>
  );
});
