import * as z from 'zod';

export const LoanStatusPendingActivation = z.literal('pendingActivation');
export const LoanStatusPreActive = z.literal('preActive');
export const LoanStatusActive = z.literal('active');
export const LoanStatusArchived = z.literal('archived');
export const LoanStatusPendingTransfer = z.literal('pendingTransfer');
export const LoanStatusTransferred = z.literal('transferred');
export const LoanStatusPaidOff = z.literal('paidOff');
export const LoanStatusForeclosure = z.literal('foreclosure');

export const LoanStatus = z.union([
  LoanStatusPendingActivation,
  LoanStatusPreActive,
  LoanStatusActive,
  LoanStatusArchived,
  LoanStatusForeclosure,
  LoanStatusPendingTransfer,
  LoanStatusTransferred,
  LoanStatusPaidOff,
]);
export type LoanStatus = z.infer<typeof LoanStatus>;

export const LoanStatusesToIncrementAmortizationMonth = z.union([
  LoanStatusActive,
  LoanStatusPendingTransfer,
  LoanStatusPendingActivation,
  LoanStatusForeclosure,
]);

export const TransferringLoanStatus = z.union([LoanStatusPendingTransfer, LoanStatusTransferred]);
