import { z } from 'zod';

import { PaymentDueId } from '../BrandedIds';
import { zodBrandedUuid, zodDateOrString } from '../utils/Zod';
import { BaseLoanIdSideEffect } from './BaseLoanIdSideEffect';

export const PhysicallyMailMonthlyStatementSideEffectContext = BaseLoanIdSideEffect.extend({
  paymentDueId: zodBrandedUuid<PaymentDueId>(),
  paymentDate: zodDateOrString,
  statementVersion: z.number(),
  auditLogComment: z.string(),
});
export const PhysicallyMailMonthlyStatementSideEffect = PhysicallyMailMonthlyStatementSideEffectContext.extend({
  type: z.literal('physicallyMailMonthlyStatement'),
});
export type PhysicallyMailMonthlyStatementSideEffect = z.infer<typeof PhysicallyMailMonthlyStatementSideEffect>;
