import * as z from 'zod';

import { TransferLoanRowData } from '../loan/LoanTransfer';
import { BaseLoanEvent } from './BaseLoanEvent';

export const TransferAccountEvent = BaseLoanEvent.extend({
  type: z.literal('transferAccount'),
  data: TransferLoanRowData(true), // Make this lenient since we don't know which company we're parsing for
});
export type TransferAccountEvent = z.infer<typeof TransferAccountEvent>;
