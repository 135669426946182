import { ErrorMessage } from '@hookform/error-message';
import classNames from 'classnames';
import { get } from 'lodash';
import { ReactNode } from 'react';
import { Control, FieldPathByValue, FieldValues, useController } from 'react-hook-form';

import { Form, FormControlProps } from '../../../../bootstrap';
import { Hint } from '../Hint';

// There might be a better way to do this, the errors in TS
// can become confusing if you forget a prop like `label`
type Props<T extends FieldValues> = {
  // Only allow string values to be used with this component
  fieldName: FieldPathByValue<T, string | undefined> | FieldPathByValue<T, number | undefined>;
  control: Control<T>;
  label?: ReactNode;
  hint?: ReactNode;
  required?: boolean;
  inputType?: 'text' | 'textarea' | 'number' | 'email' | 'password';
  hideErrorMessage?: boolean;
  textareaRows?: number;
  disabled?: boolean;
  placeholder?: string;
  displayValue?: boolean;
  inputClassnames?: string;
} & Pick<FormControlProps, 'autoFocus' | 'style'>;

export function ControlledInput<T extends FieldValues>({
  label,
  required,
  hint,
  control,
  fieldName,
  hideErrorMessage,
  inputType,
  textareaRows,
  displayValue,
  inputClassnames,
  ...props
}: Props<T>) {
  const {
    field,
    formState: { errors },
  } = useController({ name: fieldName, control });
  const textAreaProps = inputType === 'textarea' ? { as: 'textarea' as const, rows: textareaRows ?? 5 } : {};

  return (
    <>
      {label && (
        <Form.Label className="u-fs-2 fw-bold">
          {label}
          {required && <span className="u-color-red1 ps-1 ">*</span>}
        </Form.Label>
      )}
      {displayValue ? (
        <div className="u-fs-2">{field.value ?? 'N/A'}</div>
      ) : (
        <>
          <Form.Control
            className={classNames(inputClassnames, {
              'u-border-color-red1': get(errors, fieldName) && !hideErrorMessage,
            })}
            type={inputType}
            {...textAreaProps}
            {...field}
            {...props}
          />
          {hint && <Hint>{hint}</Hint>}
          {!hideErrorMessage && (
            <ErrorMessage
              errors={errors}
              name={fieldName as any} // This *should* work without casting but it doesn't
              render={({ message }) => <div className="u-fs-2 u-color-red1">{message}</div>}
            />
          )}
        </>
      )}
    </>
  );
}
