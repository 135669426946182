import { BuydownType, LoanStatus, WorkoutPlanType } from '@prisma/client'; // eslint-disable-line import/no-extraneous-dependencies
import * as z from 'zod';

import { CompanyId, LoanId, LosId, PoolId } from '../BrandedIds';
import { AmortizationType } from './Loan';
import { NonPerformingReportStatus } from './NonPerformance';
import { LoanPaymentStatus } from './Snapshots';

export interface LoanReporting {
  loanId: LoanId;
  losId: LosId;
  companyId: CompanyId;

  // Additional ids for loan
  poolId?: PoolId; // Loan pool if doing full life of loan servicing
  previousServicerId?: string; // id if the loan had previously been with another servicer
  capitalPartnerId?: string; // id of the loan investor
  fullId?: string; // Long id for loan
  otherId1?: string; // Extra id field to be used as needed
  otherId2?: string; // Extra id field to be used as needed
  otherId3?: string; // Extra id field to be used as needed
  ownerId?: string; // Id for owner of the loan if not the servicer

  name: string;
  address: string;
  status: LoanStatus;
  nonPerformingStatus: NonPerformingReportStatus;
  flagged: boolean;
  amortizationType: AmortizationType;
  interestRate: number;
  monthlyInterestRate: number;
  loanCreatedAt: Date;
  hasInterestReserve: boolean;
  buydownType?: BuydownType;
  servicer?: string;
  transferDate?: Date;
  goodbyeEmailStatus: EmailStatus;
  mortgageeEmailStatus: EmailStatus;
  autoPaymentType: AutoPaymentType;
  paymentStatus: LoanReportingPaymentStatus;
  lastPaymentDate?: Date;
  nextPaymentDate?: Date;
  paidOffDate?: Date;
  monthlyPaymentAmount: number;
  firstPaymentDueDate: Date;
  originalPrincipalBalance: number;
  outstandingPrincipalBalance: number;
  principalPrePaymentBalance: number;
  escrowBalance: number;
  suspenseBalance: number;
  maturityDate?: Date;
  totalAmountDue: number;
  expectAnyPayment: boolean;
  firstUnfulfilledPaymentDate?: Date;
  totalAmountCollected: number;
  hasBorrowerLoggedIn: boolean;
  hasBorrowerConnectedAccount: boolean;
  totalCommunicationsSent: number;
  constructionStatus: ConstructionStatus;
  entityName?: string;
  workoutPlanType: WorkoutPlanType;
}

export const EmailStatus = z.enum(['NONE', 'SCHEDULED', 'SENT']);
export type EmailStatus = z.infer<typeof EmailStatus>;
export const LoanReportingPaymentStatus = z.enum([
  ...LoanPaymentStatus.options,
  'PASTDUE_30',
  'PASTDUE_60',
  'PASTDUE_90',
]);
export type LoanReportingPaymentStatus = z.infer<typeof LoanReportingPaymentStatus>;

export const ConstructionStatus = z.enum([
  'NON_CONSTRUCTION',
  'CONSTRUCTION_ACTIVE',
  'CONSTRUCTION_TO_PERMANENT_ACTIVE',
  'CONSTRUCTION_TO_PERMANENT_PERMANENT',
]);

export type ConstructionStatus = z.infer<typeof ConstructionStatus>;

export const AutoPaymentType = z.enum(['NONE', 'BORROWER_INITIATED', 'LENDER_INITIATED']);
export type AutoPaymentType = z.infer<typeof AutoPaymentType>;

export const LoanReportingWorkoutPlanType = z.enum(['NONE', 'PAYMENT_DEFERMENT', 'REPAYMENT_PLAN']);

export type LoanReportingWorkoutPlanType = z.infer<typeof LoanReportingWorkoutPlanType>;
