import { z } from 'zod';

import { BaseLoanEvent } from './BaseLoanEvent';

/**
 * @deprecated
 * see late-payment cron + shouldSendLatePaymentEmail selector
 */
export const SendLatePaymentEmailEvent = BaseLoanEvent.extend({
  type: z.literal('sendLatePaymentEmail'),
  ignoreDateCheck: z.boolean().optional(),
});
export type SendLatePaymentEmailEvent = z.infer<typeof SendLatePaymentEmailEvent>;
