import { mapValues } from 'lodash';
import { z } from 'zod';

import { ImportRow } from '../loan/LoanImport';
import { TransferLoanRowData } from '../loan/LoanTransfer';
import { trimAndStrip } from '../validations';
import { ConstructionToPermanentRow } from '../validations/loan/ConstructionToPermanentRow';

// TODO This still kinda feels like a mess. Ideally there would be a stronger
// correlation between data stored in Flatfile and what is eventually pulled
// out. As an example update 1098 data is uploaded parsed via:
//   `packages/shared-iso/src/validations/loan/update/validateUpdateLoan.ts`
// Which uses:
//   `packages/types-iso/src/validations/loan/UpdateLoanRow.ts`
// Then we pull out using a flatfile client like so:
//   `packages/shared-node/src/flatFile/FlatFilePlatformClient.ts`
// Which uses:
//   `packages/types-iso/src/loanImport/Update.ts`
// That type above should be extending `UpdateRow` and only transforming
// data, but there aren't any guarantees of that
export const FlatfilePlatformData = <T extends z.ZodTypeAny>(schema: T) =>
  z.array(
    z.object({
      id: z.string(),
      // Use preprocessor to trim/strip nullish values into undefined
      mapped: z.preprocess((data: any) => mapValues(data, trimAndStrip), schema),
      sequence: z.number(),
    }),
  );

export const TransferRowsPlatformData = FlatfilePlatformData(TransferLoanRowData(true));
export type TransferRowsPlatformData = z.infer<typeof TransferRowsPlatformData>;

export const ImportRowsPlatformData = FlatfilePlatformData(ImportRow);
export type ImportRowsPlatformData = z.infer<typeof ImportRowsPlatformData>;

export const ConstructionToPermanentRowsPlatformData = FlatfilePlatformData(ConstructionToPermanentRow);
export type ConstructionToPermanentRowsPlatformData = z.infer<typeof ConstructionToPermanentRowsPlatformData>;
