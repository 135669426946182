import { z } from 'zod';

export const CompanyLoanControlSettings = z.object({
  // loan notes
  loanNoteCategoriesRequired: z.boolean(),

  // general notifications
  preActiveWelcomeEnabled: z.boolean(),
  pastGraceEmailEnabled: z.boolean(),
  pastGracePeriodDays: z.number(),
  pastDueNotificationCadence: z.array(z.number()), // days late

  // servicing transfer
  showTransferDateOnBusinessDay: z.boolean().optional(), // TODO: Remove this after next deploy (12/9/24)
  allowTransferDateAnyDayOfMonth: z.boolean().optional(),
});
export type CompanyLoanControlSettings = z.infer<typeof CompanyLoanControlSettings>;

export const DEFAULT_GRACE_PERIOD = 15;
export const DEFAULT_PAST_DUE_CADENCE = [1, 3];
export const DEFAULT_LOAN_CONTROL_SETTINGS: CompanyLoanControlSettings = {
  loanNoteCategoriesRequired: false,
  preActiveWelcomeEnabled: false,
  pastGraceEmailEnabled: false,
  pastGracePeriodDays: DEFAULT_GRACE_PERIOD,
  pastDueNotificationCadence: DEFAULT_PAST_DUE_CADENCE,
  allowTransferDateAnyDayOfMonth: false,
};
