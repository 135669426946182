import { z } from 'zod';

import { LoanWorkflowId } from '../BrandedIds';
import { Actor } from '../events';
import { Chapter7Step } from '../tasks/workflowTasks/Chapter7WorkflowTask';
import { Chapter13Step } from '../tasks/workflowTasks/Chapter13WorkflowTask';
import { DelinquencyStep } from '../tasks/workflowTasks/DelinquencyTask';
import { ForeclosureStep } from '../tasks/workflowTasks/ForeclosureWorkflowTask';
import { ReoStep } from '../tasks/workflowTasks/RealEstateOwnedWorkflowTask';
import { zodBrandedUuid, zodDateOrString } from '../utils';

export const LoanWorkflowStatus = z.enum(['active', 'closed']);
export type LoanWorkflowStatus = z.infer<typeof LoanWorkflowStatus>;

const BaseWorkflow = z.object({
  id: zodBrandedUuid<LoanWorkflowId>(),
  actor: Actor,
  status: LoanWorkflowStatus,
  actionStatus: z.string().optional(),
  createdAt: zodDateOrString,
  updatedAt: zodDateOrString,
});

export const ForeclosureWorkflow = BaseWorkflow.extend({
  type: z.literal('foreclosure'),
  tasks: z.array(
    z.object({
      sequence: z.number().int().gte(0),
      task: ForeclosureStep,
    }),
  ),
});
export type ForeclosureWorkflow = z.infer<typeof ForeclosureWorkflow>;

export const ReoWorkflow = BaseWorkflow.extend({
  type: z.literal('realEstateOwned'),
  tasks: z.array(
    z.object({
      sequence: z.number().int().gte(0),
      task: ReoStep,
    }),
  ),
});
export type ReoWorkflow = z.infer<typeof ReoWorkflow>;

export const Chapter7Workflow = BaseWorkflow.extend({
  type: z.literal('chapter7'),
  tasks: z.array(
    z.object({
      sequence: z.number().int().gte(0),
      task: Chapter7Step,
    }),
  ),
});
export type Chapter7Workflow = z.infer<typeof Chapter7Workflow>;

export const Chapter13Workflow = BaseWorkflow.extend({
  type: z.literal('chapter13'),
  tasks: z.array(
    z.object({
      sequence: z.number().int().gte(0),
      task: Chapter13Step,
    }),
  ),
});
export type Chapter13Workflow = z.infer<typeof Chapter13Workflow>;

export const DelinquencyWorkflow = BaseWorkflow.extend({
  type: z.literal('delinquency'),
  tasks: z.array(
    z.object({
      sequence: z.number().int().gte(0),
      task: DelinquencyStep,
    }),
  ),
});
export type DelinquencyWorkflow = z.infer<typeof DelinquencyWorkflow>;

export const LoanWorkflow = z.union([
  ForeclosureWorkflow,
  ReoWorkflow,
  Chapter7Workflow,
  Chapter13Workflow,
  DelinquencyWorkflow,
]);
export type LoanWorkflow = z.infer<typeof LoanWorkflow>;
