import { z } from 'zod';

import { EscrowCompanyId } from '../BrandedIds';
import { EscrowCompany } from '../loan/EscrowCompany';
import { zodBrandedUuid } from '../utils/Zod';
import { BaseLoanEvent } from './BaseLoanEvent';

export const UpdateEscrowCompanyEvent = BaseLoanEvent.extend({
  type: z.literal('updateEscrowCompany'),
  escrowCompanyId: zodBrandedUuid<EscrowCompanyId>(),
  newEscrowCompany: EscrowCompany.omit({ id: true }),
});

export type UpdateEscrowCompanyEvent = z.infer<typeof UpdateEscrowCompanyEvent>;
