import * as z from 'zod';

import { LosId } from '../../BrandedIds';
import { PaymentMethod } from '../../loan/Payments';
import { zodBrandedString } from '../../utils';
import { Checkbox, DateString, PositiveMonetaryValue } from '../fields';

const BulkPaymentMethod = z.enum([
  PaymentMethod.Enum.check,
  PaymentMethod.Enum.phone,
  PaymentMethod.Enum.wire,
  PaymentMethod.Enum.reserve,
]);

export const RecordPaymentRow = z.object({
  loanId: zodBrandedString<LosId>(),
  paymentMethod: z.string().toLowerCase().pipe(BulkPaymentMethod.default('check')),
  paymentDate: DateString,
  processedDate: DateString.optional(),
  totalPaymentAmount: PositiveMonetaryValue,
  additionalPrincipalAmount: PositiveMonetaryValue.default(0),
  additionalEscrowAmount: PositiveMonetaryValue.default(0),
  additionalSuspenseAmount: PositiveMonetaryValue.default(0),
  // This will be hooked up in a future PR
  // paymentPayer: z.string().optional(),
  sendPaymentNotificationFlag: Checkbox.default('yes'),
  referenceId: z.string().optional(),
});

export const RECORD_PAYMENT_ROW_REQUIRED_FIELDS = Object.keys(RecordPaymentRow.shape).filter(
  (key) => !RecordPaymentRow.shape[key as keyof typeof RecordPaymentRow.shape].isOptional(),
);
