import * as z from 'zod';

import { PhoneNumber } from '../../Phone';
import { OptionalEmailString } from '../email';
import {
  dateIsOnFirstOfMonth,
  dateIsWithinRange,
  DateString,
  Float,
  PositiveFloat,
  PositiveMonetaryValue,
  StateAbbreviation,
  Zipcode,
} from '../fields';

// NOTE: If parsing data with TransferLoanRow, make sure
// you trim and strip the data beforehand with the `trimAndStrip` util
export const TransferLoanRow = (allowTransferDateAnyDayOfMonth: boolean) =>
  z.object({
    loanId: z.string(),
    servicerName: z.string(),
    servicerLoanNumber: z.string(),
    transferEffectiveDate: allowTransferDateAnyDayOfMonth
      ? DateString.and(dateIsWithinRange({ years: 30 }))
      : DateString.and(dateIsWithinRange({ years: 30 })).and(dateIsOnFirstOfMonth),
    transferSoldDate: DateString.optional(),
    servicerPhone: PhoneNumber,
    servicerPaymentAddressLine1: z.string(),
    servicerPaymentAddressLine2: z.string().optional(),
    servicerPaymentAddressLine3: z.string().optional(),
    servicerPaymentAddressLine4: z.string().optional(),
    servicerPaymentAddressLocality: z.string(),
    servicerPaymentAddressRegion: StateAbbreviation,
    servicerPaymentAddressPostcode: Zipcode,
    servicerPaymentAddressCountry: z.string().optional(),
    servicerCorrespondenceAddressLine1: z.string().optional(),
    servicerCorrespondenceAddressLine2: z.string().optional(),
    servicerCorrespondenceAddressLine3: z.string().optional(),
    servicerCorrespondenceAddressLine4: z.string().optional(),
    servicerCorrespondenceAddressLocality: z.string().optional(),
    servicerCorrespondenceAddressRegion: StateAbbreviation.optional().or(z.literal('')),
    servicerCorrespondenceAddressPostcode: Zipcode.optional(),
    servicerCorrespondenceAddressCountry: z.string().optional(),
    servicerQwrAddressLine1: z.string().optional(), // qualified written request address
    servicerQwrAddressLine2: z.string().optional(),
    servicerQwrAddressLine3: z.string().optional(),
    servicerQwrAddressLine4: z.string().optional(),
    servicerQwrAddressLocality: z.string().optional(),
    servicerQwrAddressRegion: StateAbbreviation.optional().or(z.literal('')),
    servicerQwrAddressPostcode: Zipcode.optional(),
    servicerQwrAddressCountry: z.string().optional(),
    servicerMortgageeClauseCompanyName: z.string().optional(),
    servicerMortgageeClauseAddressLine1: z.string().optional(),
    servicerMortgageeClauseAddressLine2: z.string().optional(),
    servicerMortgageeClauseAddressLine3: z.string().optional(),
    servicerMortgageeClauseAddressLine4: z.string().optional(),
    servicerMortgageeClauseAddressLocality: z.string().optional(),
    servicerMortgageeClauseAddressRegion: StateAbbreviation.optional().or(z.literal('')),
    servicerMortgageeClauseAddressPostcode: Zipcode.optional(),
    servicerMortgageeClauseAddressCountry: z.string().optional(),
    beginningOfYearPrincipalBalance: PositiveMonetaryValue.optional(),
    create1098Form: z.boolean().optional(),
    loanOfficerName: z.string().optional(),
    loanOfficerEmail: OptionalEmailString,
    servicerWebsite: z.string().optional(),
    servicerHours1: z.string().optional(),
    servicerHours2: z.string().optional(),
    servicerHours3: z.string().optional(),
    transferPrincipalBalance: PositiveFloat.optional(),
    transferEscrowBalance: Float.optional(),
    transferReserveBalance: PositiveFloat.optional(),
  });
export type TransferLoanRow = z.infer<ReturnType<typeof TransferLoanRow>>;

// TODO typescript is not happy with this
// export const TRANSFER_LOAN_ROW_REQUIRED_FIELDS = Object.keys(TransferLoanRow(false).shape).filter(
//   (key) => !TransferLoanRow(false).shape[key as any as keyof ReturnType<typeof TransferLoanRow>].isOptional(),
// );

export const TRANSFER_LOAN_ROW_REQUIRED_FIELDS = [
  'loanId',
  'servicerName',
  'servicerLoanNumber',
  'transferEffectiveDate',
  'servicerPhone',
  'servicerPaymentAddressLine1',
  'servicerPaymentAddressLocality',
  'servicerPaymentAddressRegion',
  'servicerPaymentAddressPostcode',
];
