import * as z from 'zod';

import { PaymentAddress } from './Address';
import { PaymentStatus } from './loan/Payments';
import { EmployerIdentificationNumber } from './validations/fields';

export type DwollaEnv = 'production' | 'sandbox';
export type DwollaEnvReact = 'prod' | 'sandbox';

export const RawDwollaCustomer = z.object({
  _links: z.object({
    self: z.object({
      href: z.string().url(),
    }),
    'funding-sources': z
      .object({
        href: z.string().url(),
      })
      .optional(),
    'certify-beneficial-ownership': z
      .object({
        href: z.string().url(),
      })
      .optional(),
  }),
  id: z.string(),
  firstName: z.string(),
  lastName: z.string(),
  email: z.string().email(),
  type: z.string(),
  status: z.string(),
  created: z.string(),
});

type RawDwollaCustomer = z.infer<typeof RawDwollaCustomer>;

export interface DwollaCustomer {
  links: {
    self: string;
    fundingSources: string;
    certifyBeneficialOwnership: boolean;
  };
  id: string;
  firstName: string;
  lastName: string;
  email: string;
  type: string;
  status: string;
  created: string;
}

export const formatDwollaCustomer = (dwollaCustomer: RawDwollaCustomer): DwollaCustomer => ({
  links: {
    self: dwollaCustomer._links?.self?.href,
    fundingSources: dwollaCustomer._links?.['funding-sources']?.href || '',
    certifyBeneficialOwnership: !!dwollaCustomer._links?.['certify-beneficial-ownership'],
  },
  id: dwollaCustomer.id,
  firstName: dwollaCustomer.firstName,
  lastName: dwollaCustomer.lastName,
  email: dwollaCustomer.email,
  type: dwollaCustomer.type,
  status: dwollaCustomer.status,
  created: dwollaCustomer.created,
});

const RawDwollaBank = z.object({
  _links: z.object({
    self: z.object({
      href: z.string().url(),
    }),
    'on-demand-authorization': z.object({}).optional(),
  }),
  id: z.string(),
  status: z.string(),
  type: z.literal('bank'),
  bankAccountType: z.string(),
  name: z.string(),
  removed: z.boolean(),
  bankName: z.string(),
});

type RawDwollaBank = z.infer<typeof RawDwollaBank>;

export interface DwollaBank {
  links: {
    self: string;
  };
  id: string;
  status: string;
  type: 'bank';
  bankAccountType: string;
  name: string;
  removed: boolean;
  bankName: string;
  isAutopayAuthorized: boolean;
}

export const formatDwollaBank = (dwollaBank: RawDwollaBank): DwollaBank => ({
  links: {
    self: dwollaBank._links?.self?.href,
  },
  id: dwollaBank.id,
  status: dwollaBank.status,
  type: dwollaBank.type,
  bankAccountType: dwollaBank.bankAccountType,
  name: dwollaBank.name,
  removed: dwollaBank.removed,
  bankName: dwollaBank.bankName,
  isAutopayAuthorized: !!dwollaBank._links?.['on-demand-authorization'],
});

const RawDwollaBalance = z.object({
  _links: z.object({
    self: z.object({
      href: z.string().url(),
    }),
  }),
  id: z.string(),
  status: z.string(),
  type: z.literal('balance'),
  name: z.string(),
  removed: z.boolean(),
});

type RawDwollaBalance = z.infer<typeof RawDwollaBalance>;

export interface DwollaBalance {
  links: {
    self: string;
  };
  id: string;
  status: string;
  type: 'balance';
  name: string;
  removed: boolean;
}

export const formatDwollaBalance = (dwollaBalance: RawDwollaBalance): DwollaBalance => ({
  links: {
    self: dwollaBalance._links?.self?.href,
  },
  id: dwollaBalance.id,
  status: dwollaBalance.status,
  type: dwollaBalance.type,
  name: dwollaBalance.name,
  removed: dwollaBalance.removed,
});

export const RawDwollaBankAndBalance = z.union([RawDwollaBank, RawDwollaBalance]);

const DwollaTransferStatusEnum = z.enum(['pending', 'processed', 'failed', 'cancelled']);

export interface DwollaTransferStatus {
  status: z.infer<typeof DwollaTransferStatusEnum>;
}

export const RawDwollaTransfer = z.object({
  _links: z.object({
    self: z.object({
      href: z.string().url(),
    }),
    source: z.object({
      href: z.string().url(),
    }),
    destination: z.object({
      href: z.string().url(),
    }),
    'source-funding-source': z.object({
      href: z.string().url(),
    }),
    'destination-funding-source': z.object({
      href: z.string().url(),
    }),
    cancel: z.object({
      href: z.string().url(),
    }),
    'funded-transfer': z.object({
      href: z.string().url(),
    }),
    'funding-transfer': z.object({
      href: z.string().url(),
    }),
    failure: z
      .object({
        href: z.string().url(),
      })
      .optional(),
  }),
  id: z.string(),
  status: DwollaTransferStatusEnum,
  amount: z.object({
    value: z.string(),
    currency: z.string(),
  }),
  created: z.string(),
  correlationId: z.string(),
});
export type RawDwollaTransfer = z.infer<typeof RawDwollaTransfer>;

const RawDwollaFailure = z.object({
  _links: z.object({
    self: z.object({
      href: z.string().url(),
    }),
    'failed-funding-source': z.object({
      href: z.string().url(),
    }),
    customer: z.object({
      href: z.string().url(),
    }),
  }),
  code: z.string(),
  description: z.string(),
  explanation: z.string(),
});
type RawDwollaFailure = z.infer<typeof RawDwollaFailure>;

export const DwollaTransfer = z.object({
  links: z.object({
    self: z.string(),
    source: z.string(),
    destination: z.string(),
    sourceFundingSource: z.string().optional(),
    destinationFundingSource: z.string(),
    cancel: z.string().optional(),
    fundedTransfer: z.string().optional(),
    fundingTransfer: z.string().optional(),
    failure: z.string().optional(),
  }),
  id: z.string(),
  status: PaymentStatus,
  amount: z.object({
    value: z.string(),
    currency: z.string(),
  }),
  created: z.string(),
  correlationId: z.string().optional(),
  failureReason: z
    .object({
      description: z.string(),
      code: z.string(),
      explanation: z.string(),
    })
    .optional(),
});
export type DwollaTransfer = z.infer<typeof DwollaTransfer>;

export const formatDwollaTransfer = (
  dwollaTransfer: RawDwollaTransfer,
  failure?: RawDwollaFailure,
): DwollaTransfer => ({
  links: {
    self: dwollaTransfer._links?.self.href,
    source: dwollaTransfer._links?.source.href,
    destination: dwollaTransfer._links?.destination.href,
    sourceFundingSource: dwollaTransfer._links?.['source-funding-source']?.href,
    destinationFundingSource: dwollaTransfer._links?.['destination-funding-source']?.href,
    cancel: dwollaTransfer._links?.cancel?.href,
    fundedTransfer: dwollaTransfer._links?.['funded-transfer']?.href,
    fundingTransfer: dwollaTransfer._links?.['funding-transfer']?.href,
    failure: dwollaTransfer._links?.failure?.href,
  },
  id: dwollaTransfer.id,
  status: dwollaTransfer.status.toUpperCase() as PaymentStatus,
  amount: {
    value: dwollaTransfer.amount.value,
    currency: dwollaTransfer.amount.currency,
  },
  created: dwollaTransfer.created,
  correlationId: dwollaTransfer.correlationId,
  ...(failure
    ? {
        failureReason: {
          description: failure.description,
          code: failure.code,
          explanation: failure.explanation,
        },
      }
    : undefined),
});

export interface BorrowerCustomer {
  email: string;
  firstName: string;
  lastName: string;
}

const CompanyInput = z
  .object({
    name: z.string(),
    email: z.string(),
    phone: z.string(),
    address: z.string(),
    city: z.string(),
    state: z.string(),
    zipCode: z.string(),
    country: z.string(),
    hours1: z.string().optional(),
    hours2: z.string().optional(),
    hours3: z.string().optional(),
    paymentAddress: PaymentAddress,
    website: z.string().optional(),
    ein: EmployerIdentificationNumber.optional(),
    emailDomain: z.string().optional(),
    emailFrom: z.string().optional(),
  })
  .strict();
export type CompanyInput = z.infer<typeof CompanyInput>;

export interface LenderCustomer {
  email: string;
  firstName: string;
  lastName: string;
  businessName: string;
  address1: string;
  city: string;
  state: string;
  postalCode: string;
  ein: string;
  type: string;
  businessType: string;
  businessClassification: string;
  controller: {
    firstName: string;
    lastName: string;
    title: string;
    dateOfBirth: string;
    ssn: string;
    address: {
      address1: string;
      city: string;
      stateProvinceRegion: string;
      postalCode: string;
      country: string;
    };
  };
}

const DwollaCompanyInput = z
  .object({
    ein: z.string(),
    adminFirstName: z.string(),
    adminLastName: z.string(),
    adminEmail: z.string(),
    controllerFirstName: z.string(),
    controllerLastName: z.string(),
    controllerTitle: z.string(),
    controllerDob: z.string(),
    controllerSsn: z.string(),
  })
  .strict();
export type DwollaCompanyInput = z.infer<typeof DwollaCompanyInput>;
export const formatLenderInput = (
  companyInput: CompanyInput,
  dwollaCompanyInput: DwollaCompanyInput,
): LenderCustomer => ({
  email: dwollaCompanyInput.adminEmail,
  firstName: dwollaCompanyInput.adminFirstName,
  lastName: dwollaCompanyInput.adminLastName,
  businessName: companyInput.name,
  address1: companyInput.address,
  city: companyInput.city,
  state: companyInput.state,
  postalCode: companyInput.zipCode,
  ein: dwollaCompanyInput.ein,
  type: 'business',
  businessType: 'corporation', // possible values: corporation, llc, partnership
  // Financial services and products: '9ed3cf5f-7d6f-11e3-8af8-5404a6144203'
  businessClassification: '9ed3cf6c-7d6f-11e3-ba14-5404a6144203', // Consumer lending
  controller: {
    firstName: dwollaCompanyInput.controllerFirstName,
    lastName: dwollaCompanyInput.controllerLastName,
    title: dwollaCompanyInput.controllerTitle,
    dateOfBirth: dwollaCompanyInput.controllerDob,
    ssn: dwollaCompanyInput.controllerSsn,
    address: {
      address1: companyInput.address,
      city: companyInput.city,
      stateProvinceRegion: companyInput.state,
      postalCode: companyInput.zipCode,
      country: companyInput.country,
    },
  },
});

const BankAccountTypeEnum = z.enum(['checking', 'savings', 'general-ledger', 'loan']);

export interface UnverifiedBankRequest {
  routingNumber: string;
  accountNumber: string;
  bankAccountType: z.infer<typeof BankAccountTypeEnum>;
  name: string;
}

export interface UpdateCustomer {
  email?: string;

  // verified customer parameters
  address1?: string;
  city?: string;
  state?: string;
  postalCode?: string;

  // unverified customer parameters
  firstName?: string;
  lastName?: string;
}
