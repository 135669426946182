import * as z from 'zod';

import { PaymentStatus } from '../loan/Payments';
import { DisbursementType, TransactionMethod, TransactionType } from '../loanTransaction/LoanTransaction';
import { zodDateOrString } from '../utils/Zod';

export const DateFilter = z.object({
  startDate: zodDateOrString.optional(),
  endDate: zodDateOrString.optional(),
});
export type DateFilter = z.infer<typeof DateFilter>;

// Knowing that a date is always an ISO string can
// make things easier to work with, especially when
// we serialize things to SQS and unintenionally
// lose the Date type
export const IsoDateFilter = z.object({
  startDate: z.string().optional(),
  endDate: z.string().optional(),
  exactDate: z.string().optional(),
});
export type IsoDateFilter = z.infer<typeof IsoDateFilter>;

export const LoanTransactionDateFilter = z.object({
  type: z.literal('date'),
  key: z.literal('timestamp'),
  filter: DateFilter,
});
export type LoanTransactionDateFilter = z.infer<typeof LoanTransactionDateFilter>;

export const LoanTransactionStatusFilter = z.object({
  type: z.literal('enum'),
  key: z.literal('status'),
  filter: z.object({
    values: z.array(PaymentStatus),
  }),
});
export type LoanTransactionStatusFilter = z.infer<typeof LoanTransactionStatusFilter>;

export const LoanTransactionMethodFilter = z.object({
  type: z.literal('enum'),
  key: z.literal('method'),
  filter: z.object({
    values: z.array(TransactionMethod.unwrap().or(z.literal(null)).or(z.literal('null'))),
  }),
});
export type LoanTransactionMethodFilter = z.infer<typeof LoanTransactionMethodFilter>;

export const LoanTransactionTypeFilter = z.object({
  type: z.literal('enum'),
  key: z.literal('type'),
  filter: z.object({
    values: z.array(TransactionType),
  }),
});
export type LoanTransactionTypeFilter = z.infer<typeof LoanTransactionTypeFilter>;

export const LoanDisbursementTypeFilter = z.object({
  type: z.literal('enum'),
  key: z.literal('disbursementType'),
  filter: z.object({
    values: z.array(DisbursementType.unwrap().or(z.literal(null))),
  }),
});
export type LoanDisbursementTypeFilter = z.infer<typeof LoanDisbursementTypeFilter>;

export const LoanTransactionFilter = z.discriminatedUnion('key', [
  LoanTransactionDateFilter,
  LoanTransactionStatusFilter,
  LoanTransactionMethodFilter,
  LoanTransactionTypeFilter,
  LoanDisbursementTypeFilter,
]);
export type LoanTransactionFilter = z.infer<typeof LoanTransactionFilter>;

export const LoanTransactionColumnKey = z.union([
  z.literal('losId'),
  z.literal('referenceId'),
  z.literal('name'),
  z.literal('timestamp'),
  z.literal('type'),
  z.literal('status'),
  z.literal('method'),
  z.literal('disbursementType'),
  z.literal('amount'),
  z.literal('expectedPayment'),
  z.literal('principal'),
  z.literal('principalPrepayment'),
  z.literal('interest'),
  z.literal('escrow'),
  z.literal('fee'),
  z.literal('advance'),
  z.literal('suspense'),
  z.literal('holdback'),
  z.literal('reserve'),
  z.literal('dueDate'),
  z.literal('processedDate'),
  z.literal('deferredPrincipal'),
  z.literal('deferredInterest'),
  z.literal('deferredFee'),
  z.literal('achBatch'),
]);
export type LoanTransactionColumnKey = z.infer<typeof LoanTransactionColumnKey>;

export const LoanTransactionColumnKeys = z.array(LoanTransactionColumnKey);
export type LoanTransactionColumnKeys = z.infer<typeof LoanTransactionColumnKeys>;
